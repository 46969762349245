import React from "react";

export const Share = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={200}
            height={156}
            viewBox="0 0 200 156"
            fill="none"
            {...props}
        >
            <g clipPath="url(#clip0_1_744)">
                <rect x={42} y={68} width={116} height={76} rx={4} fill="#32393E" />
                <path d="M42 72a4 4 0 014-4h108a4 4 0 014 4v10H42V72z" fill="#FE9235" />
                <circle cx={48.5} cy={74.5} r={2.5} fill="#FFF9F1" />
                <circle cx={55.5} cy={74.5} r={2.5} fill="#FFF9F1" />
                <circle cx={62.5} cy={74.5} r={2.5} fill="#FFF9F1" />
                <circle cx={100} cy={110} r={17} fill="#555" />
                <path d="M108 110l-12 6.928v-13.856L108 110z" fill="#fff" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M123.526 32.97l-9.6 6.261 7.867 9.452.766-5.738c60.984 15.52 27.982 86.916-38.235 100.961h58.089c49.142-41.721 37.427-100.41-19.549-105.392l.662-5.544z"
                    fill="#157BF2"
                />
                <circle cx={25.0001} cy={42.2233} r={20} fill="#157BF2" />
                <mask
                    id="a"
                    style={{
                        maskType: "alpha"
                    }}
                    maskUnits="userSpaceOnUse"
                    x={5}
                    y={22}
                    width={41}
                    height={41}
                >
                    <circle cx={25.0002} cy={42.2233} r={20} fill="#157BF2" />
                </mask>
                <g mask="url(#a)">
                    <path
                        d="M16.583 52.392s-7.616 2.026-7.645 11.057c-.028 8.982 5.394 29.6 5.28 32.968-.227 6.101-9.96 6.461-6.285 6.765 4.76.406 7.757 2.563 8.91-2.119.787-3.22 4.663-34.01 4.266-39.235-.398-5.274.857-9.224-4.526-9.436z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M52.25 80.524c-.14-.201-.291-.335-.431-.537-5.485-6.99-9.345-11.292-10.208-20.29-1.301-12.866-10.545-10.642-16.195-9.355-5.677 1.246-14.347 4.98-12.767 15.27 1.15 7.368 5.014 16.614 10.427 22.224 6.159 6.347 36.535-.248 29.173-7.312z"
                        fill="#252D5C"
                    />
                    <path
                        d="M36.203 50.439s7.736-.217 10.342 8.773C49.15 68.2 51.729 91.1 50.88 94.552c-.823 3.406 10.227 5.18 6.82 6.542-3.426 1.292-5.42 2.522-7.833-1.813-1.675-3.007-14.108-32.723-15.246-38.048-1.07-5.345-3.378-8.96 1.581-10.794zM29.456 41.634s-1.972 4.67 1.827 7.598c3.89 3-9.68 9.003-9.595 6.223.178-3.635 1.885-5.668-.358-10.072l8.126-3.75z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M17.158 32.763c-1.715 3.637.145 16.077 4.141 17.47 1.723.564 6.47-2.674 7.69-8.886.507-2.58.52-7.027-3.2-10.057-1.728-1.426-6.627-2.785-8.631 1.473z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M28.512 29.346c3.939 3.048 3.565 7.164 3.535 7.877-.232 2.72-2.185 5.833-2.935 5.233-.926-.696 1.097-2.13-.76-4.294-1.859-2.165-.094-3.05-1.446-5.036-1.353-1.986-3.21-.564-8.291.938-2.056.615-2.876-1.178-2.758-2.802 1.661-4.419 8.518-5.167 12.655-1.916z"
                        fill="#32393E"
                    />
                </g>
                <circle cx={55.0001} cy={42.2233} r={20} fill="#FE9235" />
                <mask
                    id="b"
                    style={{
                        maskType: "alpha"
                    }}
                    maskUnits="userSpaceOnUse"
                    x={35}
                    y={22}
                    width={41}
                    height={41}
                >
                    <circle cx={55.0001} cy={42.2233} r={20} fill="#299BEF" />
                </mask>
                <g mask="url(#b)">
                    <path
                        d="M56.105 25.213c2.156 0 7.791.894 9.074 5.604.585 5.328 1.601 3.74 3.626 5.5 5.173 4.562-4.32 8.897 0 13.233 16.6 16.483-22.476 21.992-21.666 13.005 1.08-11.696-7.539-4.072-6.999-11.252 1.233-4.078-2.812-7.082 2.182-11.191 1.664-1.355-1.357-7.604 4.05-10.45 4.519-1.738 4.38-4.45 9.733-4.45z"
                        fill="#32393E"
                    />
                    <path
                        d="M49.947 51.17s5.536-1.553 11.903-1.022c0 0 4.51.498 5.124 1.021 5.298 4.717 5.657 12.204 6.367 28.117.71 15.914-.511 22.061-.511 22.061s-6.946 3.651-17.776 3.122c-7.181-.324-14.127-2.826-18.88-5.984 0 0 5.32-15.825 4.692-21.996-.374-3.714-.37-5.61-.362-14.216.011-8.101 7.172-10.502 7.172-10.502l2.271-.602z"
                        fill="#FE7426"
                    />
                    <path
                        d="M92.6 72.817c-1.66.725-2.87 1.133-5.035 1.397-16.606 1.967-32.501-13.277-30.862-19.342.819-2.896 4.042-7.813 12.563-2.35C76.19 56.92 85.642 65.718 94.04 58.74c0 0 1.204 7.373-1.44 14.077z"
                        fill="#FE7426"
                    />
                    <path
                        d="M51.118 43.06s2.72 4.497-.08 7.64c-2.929 3.225 10.614 8.32 9.937 5.491-.9-3.603-5.053-3.913-1.76-9.991l-8.097-3.14z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M61.579 31.891c2.276 3.697.387 15.642-3.659 17.537-2.654 1.242-7.183-.922-9.329-7.663-.874-2.778-1.334-6.51 2.166-10.686 1.61-1.93 8.21-3.532 10.822.812z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M47.362 41.72c4.847-2.617 7.111-4.488 10.227-8.646 1.874 2.546 3.31 3.528 6.514 4.534l-1.672-7.139-3.264-2.139h-3.393l-4.29.603-3.617 3.509-.505 9.278z"
                        fill="#32393E"
                    />
                </g>
                <circle cx={85} cy={42.2233} r={20} fill="#299BEF" />
                <mask
                    id="c"
                    style={{
                        maskType: "alpha"
                    }}
                    maskUnits="userSpaceOnUse"
                    x={65}
                    y={22}
                    width={40}
                    height={41}
                >
                    <circle cx={85} cy={42.2233} r={20} fill="#299BEF" />
                </mask>
                <g mask="url(#c)">
                    <path
                        d="M79.032 49.115s6.297-1.998 12.664-1.466c0 0 2.433.635 3.047 1.158 5.298 4.718 6.974 12.511 7.683 28.425.71 15.913-.511 22.06-.511 22.06s-6.946 3.652-17.776 3.122c-7.181-.323-14.127-2.825-18.88-5.983 0 0 5.32-15.826 4.692-21.997-.374-3.713-.637-7.682-.63-16.287.012-8.102 7.032-8.267 7.032-8.267l2.68-.765z"
                        fill="#157BF2"
                    />
                    <path
                        d="M124.906 73.292c-1.662.724-2.871 1.133-5.036 1.397-16.606 1.967-32.501-13.277-30.862-19.342.82-2.896 4.042-7.814 12.563-2.35 6.925 4.396 16.376 13.195 24.775 6.218 0 0 1.204 7.373-1.44 14.077z"
                        fill="#157BF2"
                    />
                    <path
                        d="M76.6 56.94s2.459-3.825 6.993-1.148c5.353 3.114 11.197 16.774 19.5 18.74 8.303 1.913 12.236-2.24 15.24-4.753 3.059-2.459 3.113-4.808 5.025-6.065 1.912-1.256 3.278 1.093-.601 6.174-1.857 2.404-9.613 15.572-19.937 15.08-13.547-.655-31.408-22.292-26.22-28.029z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M117.35 68.468l3.878 4.972s-7.046 16.392-23.27 12.403c-16.222-3.989-25.727-23.877-22.012-28.958 1.748-2.459 6.554-5.846 12.563 2.24 6.008 8.032 13.328 23.658 28.841 9.343z"
                        fill="#157BF2"
                    />
                    <path
                        d="M81.204 40.584s2.72 4.497-.08 7.64c-2.93 3.225 10.613 8.32 9.937 5.491-.901-3.603-2.898-5.486-1.761-9.992l-8.096-3.14z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M87.195 30.137c3.275 2.437 7.98 14.851 5.359 18.2-1.158 1.483-6.754 1.005-10.965-4.093-1.726-2.104-4.034-6.178-2.4-10.912.75-2.186 4.213-6.08 8.007-3.195z"
                        fill="#FFB69E"
                    />
                    <path
                        d="M87.166 37.434c.152.473-.12 1.231-.662 1.405-.543.175-1.154-.318-1.306-.791-.152-.473.213-.748.755-.922.543-.174 1.06-.165 1.213.308zM90.925 36.012c.132.411-.053 1.055-.466 1.187-.412.133-.899-.31-1.031-.722-.133-.412.14-.637.552-.77.413-.132.812-.107.945.305z"
                        fill="#32393E"
                    />
                    <path
                        d="M90.32 31.2c.856 1.47.71 1.886-3.693 3.397-4.404 1.512-5.205 1.34-4.6 2.42 1.775 3.176-1.502 3.086.638 6.9 2.14 3.814-1.924 1.933-4.012-1.087-.43-.596-2.755-3.555-1.186-9.03 1.765-6.383 9.752-8.185 12.852-2.6z"
                        fill="#32393E"
                    />
                    <path
                        d="M82.612 43.905s.83 1.073 3.082.216c2.305-.845 5.597-3.937 6.837-3.726 1.24.21 2.282 7.31-.12 8.86-2.39 1.498-9.033-.602-11.044-3.718-.408-.702 1.245-1.632 1.245-1.632z"
                        fill="#32393E"
                    />
                    <path
                        d="M77.403 33.842c-1.703 5.055.766 8.38 1.185 9.03 1.574 2.294 3.155 4.032 5.943 5.358.309.123-1.693-1.482-1.368-2.474.414-1.14-2.513-1.044-2.082-4.08.43-3.038-1.409-3.322-1.307-5.87.101-2.55 1.856-2.396 6.863-4.113 2.11-.72 2.36-1.616 1.986-2.478-3.663-2.968-9.395-.737-11.22 4.627z"
                        fill="#32393E"
                    />
                    <path
                        d="M89.008 45.338s.75-2.186 2.235-2.593c1.526-.342 1.486 1.661 1.272 2.397-.268.725-2.425 2.44-3.66 1.951-1.183-.478-.18-.989.153-1.755z"
                        fill="#FFB69E"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1_744">
                    <path fill="#fff" d="M0 0H200V156H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
